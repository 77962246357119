  .navbar-logo img {
    margin-right: auto; 
    height: 100px; 
  }
  
  .navbar-item {
    margin-left: 20px; /* Odstęp między elementami nawigacji */
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    letter-spacing: 2px;
  }
  
  .navbar-item a {
    text-decoration: none;
    color: #000; /* Kolor linku */
    font-size: 16px;
  }
  
  .navbar-item a:hover {
    text-decoration: underline; /* Efekt hover */
  }
  