  /* Ładowanie */
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  .display-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
  }
  
  .flex-spacer {
    flex-grow: 1;
  }
  
  /* Style for the Add Product button */
  .add-product-btn {
    padding: 8px 16px;
    font-size: 16px;
    background-color: black;
    color: white !important;
    border: 2px solid black;  /* Border with 2px thickness */
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .add-product-btn:hover {
    background-color: white;
    color: black !important;
    border-color: black;
  }
  
  .add-product-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Blue glow on focus */
  }
  
  /* Optional: Style for the search container, align to the right */
  .search-container {
    display: flex;
    justify-content: flex-end; /* Align items to the right */
    align-items: center;
    padding: 16px;
  }
  .inheritButton{
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  .acton-icon {
    color: gray;
    cursor: pointer; /* Dodaje efekt "kliknięcia" */
    margin-right: 5%;
    transition: color 0.2s ease; /* Płynna zmiana koloru */
  }
  
  .acton-icon:hover {
    color: black; /* Zmiana koloru na czarny po najechaniu */
  }
