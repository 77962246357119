@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;400&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import './table.css';

.roboto-thin {
  font-weight: 100;
  font-style: normal;
  letter-spacing: 2px;
  color: #212529;
}


.container{
  max-width: none !important;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .dialog {
    background-color: white;
    width: 50vw;
    height: max-content;
    padding: 40px;
    border-radius: 2px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  .title{
    height: 50%;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 18px;
    color: #000000;
    text-decoration: none solid rgb(0, 0, 0);
    letter-spacing: 2px;
  }

  .cancel{
    color: black !important;
    background-color: white !important;
    border: 2px solid black !important;
    font-weight: 300 !important;
    letter-spacing: 1px;
    border-radius: 0px !important;
    text-transform: none !important;
  }
  .cancel:hover{
    background-color: #ededed !important;
  }
  .save{
    background-color: black !important; 
    color: white !important;
    border: 2px solid black !important;
    font-weight: 300 !important;
    letter-spacing: 1px;
    border-radius: 0px !important;
    text-transform: none !important;
  }
  .save:hover {
    background-color: white !important;
    color: black !important ;
    border: 2px solid;
  }
.confirm-buttons{
  margin-top: 0 !important;
  padding: 0 !important;
  margin-bottom: 0 !important;
}



.left{
  margin-right: 20px;
}

.search-term {
  display: flex;
  font-size: 16px;
  border: none;
  outline: none;
  transition: border-color 0.3s ease;
}
.search-term-inside {
  border-bottom: 2px solid #d1d1d1;  /* Blue underline */
  padding: 0 10px;
}
.search-label {
  font-weight: 300 !important;
  color: black !important;  /* Ustawienie koloru etykiety na czarny */
}
.search-input .MuiFormLabel-root {
  left: -5px !important;
}

.search-input {
  height: 100%;
  font-size: 16px;
  width: 100%;
  background-color: white !important;
  border: 0;
  text-align: center;
}
.search-input:focus {
  border-color: #0056b3;  /* Darker blue when focused */
}

.search-icon-container {
  width: 40px !important;
  height: 40px;
  background-color: #ededed;
  transition: all 0.2s ease; /* Dodano płynne przejście */
  border: solid 2px #ededed;
}
.search-icon-container:hover {
  border: solid 2px black;
  background-color: white;
}
.search-icon {
  margin: auto;
}

.search-icon{
  width: auto !important;
  transform: scale(1.5);
  cursor: pointer;
}

button{
  box-shadow: none !important;
}

.css-1mb1do-MuiInputBase-root-MuiFilledInput-root{
  border-radius: 2px !important;
}

.selectError{
  background-color: rgba(0, 0, 0, 0.06);
  margin: 0 !important;
  padding-left: 14px !important;
  padding-right: 14px !important;
}