.container {
  width: 100%;
  margin: 0 !important;
}
label, .MuiFormControlLabel-label {
  font-family: "Roboto", sans-serif !important;
  font-weight: 300 !important;
  font-style: normal !important;
  letter-spacing: 1px !important;
  color: black !important;
}
.row {
  margin: 20px 0;
}
.main {
  height: 70%;
  margin: 0 !important;
}
.title {
  text-decoration: none;
}
.title-name {
  display: inline;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px;
  color: #212529;
  text-decoration: none solid rgb(33, 37, 41);
  letter-spacing: 2px;
  margin-left: 30px;
  border-bottom: 2px solid gold;
}

.textures-container {
  display: flex;
  gap: 10px;
}
.block img {
  height: 40px;
  width: 40px;
}
.block {
  cursor: pointer;
}
.custom-input {
  background-color: #f0f0f0;
  border-radius: 2px !important;
}
.custom-input input {
  background-color: #f0f0f0;
  border-radius: 2px !important;
}
.css-1r6ga1-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input {
  background: #f0f0f0 !important;
}
.css-1mb1do-MuiInputBase-root-MuiFilledInput-root {
  background-color: rgba(0, 0, 0, 0) !important;
}

input,
.MuiSelect-select {
  padding: 8px !important;
  min-width: 100px !important;
}
.container3d {
  width: 100%;
  height: 100%;
  border: 3px solid #f5f5f5;
  /* min-height: 300px; */
}
.buttons {
  margin-top: 20px !important;
  padding: 0 !important;
}
Button {
  width: 80%;
  border-radius: 2px;
}
.noBorder {
  background-color: #ededed !important;
  color: black !important;
  border: 2px solid #ededed !important;
}
.noBorder:hover {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
  border: 2px solid;
}
