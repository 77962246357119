table thead{
    font-family: "Roboto", serif;
    font-style: normal;
    letter-spacing: 1px;
  }
  table{
    font-family: "Roboto", serif;
    font-weight: 100;
    font-style: normal;
  }
/* Główne kontenery */
.table-container {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .table-content {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
    transition: all 0.3s ease;
  }
  
  .table-content th,
  .table-content td {
    padding: 12px 16px;
    text-align: left;
    font-size: 16px;
    font-weight: 300;
  }
  .table-content th {
    border-bottom: 2px solid #d1d1d1;
    font-weight: 400;
  }
  
  .table-content td {
    border-bottom: 1px solid #ddd;
  }
  
  /* Wiersze */
  .table-content tr:nth-child(even) {
    background-color: #f4f4f4;
  }
  
  /* Wiersze po najechaniu myszką */
  .table-content tr:hover {
    background-color: #f1f1f1;
  }
.table-container-inside{
    overflow-x: auto; /* Dodaje przewijanie w poziomie */
    -webkit-overflow-scrolling: touch; /* Płynne przewijanie na urządzeniach mobilnych */
  }
  .table-content {
    width: 100%; /* Tabela wypełnia dostępny obszar */
    min-width: 800px; /* Minimalna szerokość, wymuszająca przewijanie */
    border-collapse: collapse; /* Dobra praktyka dla tabel */
  }
    /* Pagination container */
    .pagination {
        display: flex;
        align-items: center;
        gap: 16px;
      }
  /* Select dropdown style */
.pagination select {
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid #ddd;
    font-size: 14px;
    background-color: white;
    cursor: pointer;
    transition: border-color 0.3s ease;
  }
  
  .pagination select:hover {
    border-color: #3f51b5;
  }
  
  /* Page info text (like 'Strona 1 z 3') */
  .pagination-info {
    font-size: 14px;
    color: #616161 !important;
  }
  
  
  /* Pagination buttons */
.pagination button {
    padding: 8px 14px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin: 0 4px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .pagination button:hover {
    background-color: #313131;
    transform: scale(1.05);
  }
  
  .pagination button:disabled {
    background-color: #b0bec5;
    cursor: not-allowed;
    transform: none;
  }
  
  .pagination .MuiPaginationItem-root {
    font-size: 14px;
    color: white;
    padding: 8px 14px;
    border-radius: 4px;
    margin: 0 4px;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  
  .pagination .MuiPaginationItem-root:hover {
    background-color: #313131 !important;
    transform: scale(1.05);
  }
  
  .pagination .MuiPaginationItem-root.Mui-selected {
    background-color: #313131;
    color: white;
  }  