* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

canvas {
  width: 100%;
  height: 100%;
  cursor: grab;
}

canvas:active {
  cursor: grabbing;
}

.circleBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: black;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.circleBtn:hover {
  transform: translate(-50%, -50%) scale(1.13);
}

.texture-container {
  z-index: 999;
  position: fixed;
  top: 2%;
  right: 3%;
  display: flex;
  flex-direction: column;
}

.texture-button {
  width: 40px;
  height: 40px;
  margin: 5px;
  background-size: cover;
  background-position: center;
  border: none;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.texture-button:hover {
  transform: scale(1.1);
}

.texture-button.texture-selected {
  border: 3px solid #e0b000;
  box-sizing: border-box;
  transform: translateY(-3px);
}

.texture-text {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  color: #212529;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word;
}
