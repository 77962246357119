.loader-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.loader-text {
  font-size: 16px;
  margin-top: 50px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.loader-container {
  display: grid;
  place-items: center;
  border-radius: 4px;
  transition: opacity 0.4s ease;
}

.loader {
  --color: rgba(0, 0, 0, 0.7);
  --size-square: 30px;

  display: block;
  position: relative;
  width: 50%;
  display: grid;
  place-items: center;
}

.loader::before,
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: var(--size-square);
  height: var(--size-square);
  background-color: var(--color);
}

.loader::before {
  top: calc(50% - var(--size-square));
  left: calc(50% - var(--size-square));
  animation: loader-1 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

.loader::after {
  top: 50%;
  left: 50%;
  animation: loader-2 2.4s cubic-bezier(0, 0, 0.24, 1.21) infinite;
}

@keyframes loader-1 {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(100%);
  }

  50% {
    transform: translateX(100%) translateY(100%);
  }

  75% {
    transform: translateY(100%);
  }
}

@keyframes loader-2 {
  0%,
  100% {
    transform: none;
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-100%) translateY(-100%);
  }

  75% {
    transform: translateY(-100%);
  }
}
